import React, { useState, useEffect } from 'react';
import { Typography, Row, Col } from 'antd';
import { Button, Space } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { FileOutlined, GithubOutlined, TeamOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const safetyStyle = {
	backgroundColor: '#53B9FF',
	padding: '0 5px',
	color: "white",
	whiteSpace: 'nowrap'
};

const HeaderPage = () => {

	const [isMobile, setIsMobile] = useState(false);
	const [showAuthor, setShowAuthor] = useState(false);

	useEffect(() => {
		setIsMobile(window.innerWidth <= 768);
	}, [window.innerWidth]);

	const authors = [
		{ name: "Andy Zou", affiliation: "1,2,3", bioLink: "https://andyzoujm.github.io" },
		{ name: "Long Phan", affiliation: "3", bioLink: "https://longphan.ai/" },
		{ name: "Justin Wang", affiliation: "1", bioLink: "" },
		{ name: "Derek Duenas", affiliation: "1", bioLink: "" },
		{ name: "Maxwell Lin", affiliation: "1", bioLink: "" },
		{ name: "Maksym Andriushchenko", affiliation: "1", bioLink: "https://www.andriushchenko.me" },
		{ name: "Rowan Wang", affiliation: "1", bioLink: "" },
		{ name: "Zico Kolter", affiliation: "1,2", bioLink: "https://zicokolter.com" },
		{ name: "Matt Fredrikson", affiliation: "1,2", bioLink: "https://mattfredrikson.com/" },
		{ name: "Dan Hendrycks", affiliation: "1,3", bioLink: "https://people.eecs.berkeley.edu/~hendrycks/" }
	];

	const affiliations = [
		{ name: "Gray Swan AI", bioLink: "https://grayswan.ai/" },
		{ name: "Carnegie Mellon University", bioLink: "https://www.cmu.edu/" },
		{ name: "Center for AI Safety", bioLink: "https://www.safe.ai/" },
	];

	const logoList = [
		{ src: "images/bss_logo.png", width: "15px", v: "15%", l: "0px" },
		{ src: "images/cmu_logo.png", width: "15px", v: "15%", l: "2px" },
		{ src: "https://assets-global.website-files.com/63fe96aeda6bea77ac7d3000/63fe9a72750bc67b129df210_CAIS%20Logo.svg", width: "15px", v: "15%", l: "2px" }
	];


	let breakAuthorIndices = isMobile ? [2,4] : [4, 7, 13]

	const author_component = <div style={{ marginTop: "2vh" }}>
		<p className='header__member'>
			{authors.map((author, index) => (
				<span>
					<span key={index} style={{ display: 'inline-block', marginRight: '10px' }}>
						{author.bioLink ? (
							<a href={author.bioLink} target="_blank" style={{ color: "#0A74DA" }}>
								{author.name}
							</a>
						) : (
							<span style={{ color: "#0A74DA" }}>{author.name}</span>
						)}
						{author.affiliation.split(',').map((affIndex) => (
							<img
								key={affIndex}
								src={logoList[parseInt(affIndex) - 1].src}
								style={{
									width: logoList[parseInt(affIndex) - 1].width,
									verticalAlign: logoList[parseInt(affIndex) - 1].v,
									marginLeft: logoList[parseInt(affIndex) - 1].l
								}}
							/>
						))}
						{index !== authors.length - 1 && ", "}
					</span>
					{breakAuthorIndices.includes(index) && <br />}
				</span>

			))}
		</p>

		<p className='header__note'>
			{affiliations.map((affiliation, index) => (
				<span style={{ color: "#787878", display: 'inline-block' }} key={index}>
					<img src={logoList[index].src} style={{ width: logoList[index].width, verticalAlign: logoList[index].v, marginRight: "2px" }} />
					<a href={affiliation.bioLink} target="_blank">
						{affiliation.name}
					</a>
					{index !== affiliations.length - 1 && ",  "}
				</span>
			))}
		</p>
		<br />
	</div>



	const githubLink = "https://github.com/GraySwanAI/circuit-breakers"
	const hfCollectionLink = "https://huggingface.co/collections/GraySwanAI/model-with-circuit-breakers-668ca12763d1bc005b8b2ac3"
	const paperLink = "https://arxiv.org/abs/2406.04313"

	return (
		<div className="page-container" style={{ maxWidth: "90%" }}>
			<Row gutter={[20, 20]} style={{ marginTop: "2v", display: 'flex', alignItems: 'center' }}>
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Title level={1} className='header__title'>
						Improving Alignment and Robustness with <span style={safetyStyle}>Circuit Breakers</span>
					</Title>
					<div style={{ alignItems: 'center', textAlign: 'center', marginTop: '20px' }}>
						<Space>
							<Button ghost className="customDashedButton" onClick={() => setShowAuthor(!showAuthor)}>
								<div className='header__button' style={{ textAlign: 'center', color: 'black' }}>
									<TeamOutlined />
									<span style={{ marginLeft: "5px" }}>{showAuthor ? <CaretUpOutlined style={{ color: 'black' }} /> : <CaretDownOutlined style={{ color: 'black' }} />}</span>
								</div>
							</Button>
							<Button ghost className='customDashedButton' href={paperLink} target="_blank">
								<span className='header__button' style={{ textAlign: 'center', color: 'black' }}>
									<FileOutlined /> Paper
								</span>
							</Button>
							<Button ghost className='customDashedButton' href={githubLink} target="_blank">
								<span className='header__button' style={{ textAlign: 'center', color: 'black' }}>
									<GithubOutlined /> GitHub
								</span>
							</Button>
							{!isMobile && <Button ghost className='customBlogButton' href={hfCollectionLink} target="_blank">
								<span className='header__button' style={{ textAlign: 'center', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
									<img style={{ width: "25px", marginRight: "5px", alignSelf: 'center' }} src="images/hf-logo.png" />Collection
								</span>
							</Button>}
						</Space>

						{showAuthor && <div >
							{author_component}
						</div>}

						<Paragraph className='body__abstract' style={{ marginTop: !showAuthor ? '20px' : '0px', textAlign:'left' }}>
							We present a novel approach called "circuit breakers" to prevent harmful outputs from AI systems, which are often vulnerable to adversarial attacks. Unlike traditional methods like refusal training, which can be circumvented, or adversarial training, which targets specific threats, circuit breakers proactively manage the underlying representations causing harmful outputs. This technique is applicable to both text-only and multimodal language models and ensures their functionality remains intact even under powerful, novel attacks. Additionally, it enhances the robustness of multimodal systems against image-based attacks and reduces harmful actions by AI agents, marking a substantial advancement in developing effective safeguards against harmful behaviors and adversarial attacks.
						</Paragraph>

					</div>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<img
						src='images/fig1.svg'
						alt='outline'
						style={{
							marginTop: "2vh",
							width: '90%',
							height: 'auto',
							padding: '10px',
							backgroundColor: 'white',
							borderRadius: '15px'
						}}
					/>
				</Col>

			</Row>



		</div>
	);
};

export default HeaderPage;

import React from 'react';
import { Typography, Divider } from 'antd';
import Citation from './citation';
import Overview from './overview';
import LLM from './llm';
import Multimodal from './multimodal'
import Agent from './agent'

const { Title, Paragraph } = Typography;

const Home = () => {
	return (
		<div style={{ marginTop: "4vh" }}>
			<div className="page-container">
				<Overview />
				<Divider />
				<Title level={2} style={{ textAlign: 'center', marginTop: '3rem', marginBottom: '2rem' }}>Large Language Models</Title>
				<LLM />
				<Divider style={{ marginTop: '3rem' }} />
				<Title level={2} style={{ textAlign: 'center', marginTop: '3rem', marginBottom: '2rem' }}>Multimodal Models</Title>
				<Multimodal />
				<Divider style={{ marginTop: '3rem' }} />
				<Title level={2} style={{ textAlign: 'center', marginTop: '3rem', marginBottom: '2rem' }}>Agents</Title>
				<Agent />
			</div>

			<Divider style={{ marginTop: '4rem' }} />

			<div className="page-container">
				<Paragraph style={{ fontSize: '11px', lineHeight: '1.6', marginTop: '2rem' }}>
					<Title level={4}>Citation:</Title>
					<div className="citation-block">
						<Citation />
					</div>
				</Paragraph>
			</div>
		</div>
	);
};

export default Home;
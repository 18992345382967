import React from 'react';
import { Link, useLocation, Routes, Route } from 'react-router-dom';

import './assets/style/index.scss';
import Home from './components/body/home';
import HeaderPage from './components/body/header';
import { Typography } from 'antd';
const App = () => {

    const location = useLocation(); // This hook gives you the current location.
    const showHeaderPage = location.pathname === '/' || location.pathname === '/about';

    const linkStyle = (path) => ({
        color: "black", 
        fontSize: "2vh", 
        textDecoration: 'none',
        paddingBottom: '3px',
        borderBottom: location.pathname === path ? '3px solid #3A6B65' : '3px solid transparent',
        display: 'inline-block',
    });

    return (
        <div style={{ backgroundColor: '#DDEFFE', width: "100%" }}>
            <div style={{
                backgroundColor: '#DDEFFE',
                paddingBottom: '2vh',
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    paddingTop: "1vh",
                    width: '95%'
                }}>
                </div>
                {showHeaderPage && (
                    <div style={{ width: '100%' }}>
                        <HeaderPage />
                    </div>
                )}
            </div>

            <div className="App" style={{
                backgroundColor: 'white',
                borderTopLeftRadius: '5vh',
                borderTopRightRadius: '5vh',
                overflow: 'hidden',
                width: '100%',
                maxWidth: '100%',
                boxSizing: 'border-box'
            }}>
                <div>
                    <Routes>
                        <Route path="/" element={<Home />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default App;

import React from 'react';
import { Row, Col, Typography } from 'antd';

const { Title, Paragraph } = Typography;

function Multimodal() {
    return (
        <Row gutter={[20, 20]} style={{ marginTop: "2vh" }}>
            <Col xs={22} sm={22} md={10} lg={10} xl={10}>
                <div id='abstract' className='section abstract'>
                    <Paragraph className='body__paragraph'>
                    Under Projected Gradient Descent (PGD) attack, our LLaVA-NeXT-Mistral-7B (+ RR) with circuit
                    breakers is significantly more robust compared to the original model even with a safety prompt that
                    instructs the model to avoid harmful responses. Performance on multimodal capabilities benchmarks
                    MMMU and LLaVA-Wild is preserved.
                    This demonstrates that despite the ongoing challenge of achieving adversarial robustness in standalone image recognition, circuit breakers enable
                    the larger multimodal system to reliably counter image “hijacks” intended to elicit harmful outputs.
                    </Paragraph>
                </div>
            </Col>
            <Col xs={26} sm={26} md={14} lg={14} xl={14}>
                <div>
                    <img src='images/mm_res.png'
                        style={{
                            width: '90%',
                            height: 'auto',
                            backgroundColor: 'white',
                            borderRadius: '15px'
                        }}
                        alt='outline' />
                </div>
                {/* <Paragraph className='body__figure_note' style={{width: "90%"}}>
                    <strong>Multimodal evaluation results.</strong> Circuit-breaking performance in multimodal settings with Representation Rerouting (RR).
                </Paragraph> */}
            </Col>
        </Row>
    );
}

export default Multimodal;

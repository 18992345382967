const lineStyle = {
    paddingLeft: '2em', // Adjust '2em' to increase or decrease the indentation
};

const Citation = () => (

    <div>
        {"@misc{zou2024circuitbreaker,"}<br/>
        <div style={lineStyle}>{"title={Improving Alignment and Robustness with Circuit Breakers},"}</div>
        <div style={lineStyle}>{"author={Andy Zou and Long Phan and Justin Wang and Derek Duenas and Maxwell Lin and Maksym Andriushchenko and Rowan Wang and Zico Kolter and Matt Fredrikson and Dan Hendrycks},"}</div>
        <div style={lineStyle}>{"year={2024},"}</div>
        <div style={lineStyle}>{"eprint={2406.04313},"}</div>
        <div style={lineStyle}>{"archivePrefix={arXiv},"}</div>
        <div style={lineStyle}>{"primaryClass={cs.LG}"}</div>
        <div>{"}"}</div>
    </div>
);

export default Citation;

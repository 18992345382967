import React from 'react';
import { Row, Col, Typography } from 'antd';

const { Title, Paragraph } = Typography;

function Agent() {
    return (
        <Row gutter={[20, 20]} style={{ marginTop: "2vh" }}>
            <Col xs={22} sm={22} md={10} lg={10} xl={10}>
                <div id='abstract' className='section abstract'>
                    <Paragraph className='body__paragraph'>
                        We curate a dataset that consists of 100 requests intended to produce harmful actions via function calls, along with associated function definitions. 
                        These requests span a variety of categories, including cybercrime, disinformation, fraud, and harassment. Our
                        Llama-3-8B-Instruct (+ RR) with circuit breakers remains robust under Direct Request and Forced
                        Function Calls, while retaining performance on the Berkeley Function Calling Leaderboard (BFCL).
                    </Paragraph>
                </div>
            </Col>
            <Col xs={26} sm={26} md={14} lg={14} xl={14}>
                <div>
                    <img src='images/agent.png'
                        style={{
                            width: '90%',
                            height: 'auto',
                            backgroundColor: 'white',
                            borderRadius: '15px'
                        }}
                        alt='outline' />
                </div>
                {/* <Paragraph className='body__figure_note' style={{width: "90%"}}>
                    <strong>Agent Bench Results.</strong> Circuit-breaking performance in AI agent settings with Representation Rerouting (RR).
                </Paragraph> */}
            </Col>
        </Row>
    );
}

export default Agent;

import React from 'react';
import { Row, Col, Typography } from 'antd';

const { Title, Paragraph } = Typography;

function LLM() {
    return (
        <Row gutter={[20, 20]} style={{ marginTop: "2vh" }}>
            <Col xs={22} sm={22} md={10} lg={10} xl={10}>
                <div id='abstract' className='section abstract'>
                    <Paragraph className='body__paragraph'>
                        Building from techniques in <a href="https://www.ai-transparency.org" target="_blank" rel="noopener noreferrer">Representation Engineering</a>, we accomplish
                        this by remapping the sequence of model representations that leads to harmful outputs, directing
                        them towards incoherent or refusal representations -- namely, "breaking the circuit," or "shorting the
                        circuit" as one might put it. Moreover, by directly targeting the processes involved in generating
                        harmful responses, our method can generalize across the diverse range of inputs that may activate
                        those processes. Consequently, we do not need to identify all of the potential inputs that could trigger
                        undesirable outputs, rather we only need to ensure coverage of a well defined set of such outputs.
                    </Paragraph>
                    <Paragraph className='body__paragraph'>
                        Our circuit-breaking method Representation Rerouting (RR) shows
                        strong generalization across a diverse range of unseen attacks, significantly reducing compliance
                        rates to harmful requests while preserving model capability. Cygnet, a Llama-3-8B-Instruct finetune
                        integrating circuit breakers and other representation control methods, surpasses original capabilities and demonstrates a significant reduction in harmful output by roughly two orders of magnitude
                        under strong attacks. This advancement shows promising initial steps in balancing capability and
                        harmlessness in LLMs.
                    </Paragraph>
                </div>
            </Col>
            <Col xs={26} sm={26} md={14} lg={14} xl={14}>
                <div>
                    <img src='images/llm_res.png'
                        style={{
                            width: '90%',
                            height: 'auto',
                            backgroundColor: 'white',
                            borderRadius: '15px'
                        }}
                        alt='outline' />
                </div>
                {/* <Paragraph className='body__figure_note' style={{width: "90%"}}>
                    <strong>LLM evaluation results.</strong> Our circuit-breaking method Representation Rerouting (RR) shows
                    strong generalization across a diverse range of unseen attacks, significantly reducing compliance
                    rates to harmful requests while preserving model capability. Cygnet, a Llama-3-8B-Instruct finetune
                    integrating circuit breakers and other representation control methods, surpasses original capabilities and demonstrates a significant reduction in harmful output by roughly two orders of magnitude
                    under strong attacks. This advancement shows promising initial steps in balancing capability and
                    harmlessness in LLMs.
                </Paragraph> */}
            </Col>
        </Row>
    );
}

export default LLM;
